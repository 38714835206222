import { ApolloError } from '@apollo/client';

import { APIErrorCode } from '../../types';

const apiErrorMap = {
  [APIErrorCode.Unauthorised]: 401,
};

class APIError {
  message: string;
  statusCode: number;

  constructor(error: ApolloError) {
    this.message = error.message;
    const code: APIErrorCode = error.graphQLErrors.map(
      (err) => err.extensions?.code,
    ) as any;
    this.statusCode = apiErrorMap?.[code] || 500;
  }
}

export default APIError;
