import Callback from './Callback';
import Integration from './Integration';
import MatchingCandidates from './MatchingCandidates';
import SimilarCandidates from './SimilarCandidates';
import Buttons from './Buttons';
import Root from './Root';

import { SCENE_KEY } from '../sharedConfig';

//  Add all scenes to this export.
//  To see the current shared config see the sharedConfig.js file.
const scenes = {
  [SCENE_KEY.CALLBACK]: Callback,
  [SCENE_KEY.INTEGRATION]: Integration,
  [SCENE_KEY.MATCHING_CANDIDATES]: MatchingCandidates,
  [SCENE_KEY.SIMILAR_CANDIDATES]: SimilarCandidates,
  [SCENE_KEY.BUTTONS]: Buttons,
  [SCENE_KEY.ROOT]: Root,
};

export default scenes;
