import { createContext } from 'react';

import { ContextType } from './types';

export default createContext<ContextType>({
  isAuthenticated: false,
  showAccountSelector: false,
  tscAccounts: [],
  loading: true,
  error: null,
  init: () => Promise.resolve(),
  setAdvertiserId: () => null,
  getAdvertiserId: () => null,
  getAtsId: () => null,
  getAtsName: () => null,
  getUserId: () => null,
  getToken: () => null,
  getIdentity: () => Promise.resolve(null),
  handleRedirect: () => Promise.resolve(),
  renewToken: () => Promise.resolve(null),
  signIn: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
});
