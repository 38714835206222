import React, { useEffect, useMemo, useState, useRef } from 'react';

import { useAuth } from '../useAuth';

import Context from './Context';

import { ContextType, Details } from './types';

interface Props {
  children: React.ReactNode;
}

const extractIdentityDetails = (
  identity: any,
): {
  actorEmail: string | null;
  actAsEmail: string | null;
  actAsId: string | null;
} => ({
  actorEmail: identity?.act?.email || null,
  actAsEmail: identity?.sub?.email || null,
  actAsId: identity?.sub?.ID || null,
});

function ImpersonationProvider({ children }: Props) {
  const { isAuthenticated, getIdentity } = useAuth();

  const [isImpersonating, setIsImpersonating] = useState<boolean>(false);

  const detailsRef = useRef<Details | null>(null);

  useEffect(() => {
    async function setImpersonating() {
      const identity = await getIdentity();

      const { actorEmail, actAsEmail, actAsId } =
        extractIdentityDetails(identity);

      //  Ensure we have all of the things we need
      //  before we can safely say the user is being impersonated
      if (!actorEmail || !actAsEmail || !actAsId) {
        return;
      }

      detailsRef.current = {
        actorEmail,
        actAsEmail,
        actAsId,
      };

      setIsImpersonating(true);
    }

    if (isAuthenticated) {
      setImpersonating();
    }
  }, [getIdentity, isAuthenticated]);

  const context = useMemo<ContextType>(
    () => ({
      isImpersonating,
      getDetails: () => detailsRef.current,
    }),
    [isImpersonating],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

export default ImpersonationProvider;
