import fetch from 'unfetch';
import {
  getClassificationFromSiteName,
  getSiteNameFromHostname,
  Country,
} from '@seek/melways-sites';

import {
  ApiUrl,
  ErrorCode,
  TalentSearchAccount,
  TalentSearchAccounts,
  TalentSearchUserContextResponse,
} from '../../../types';

export const GET_TALENT_SEARCH_ACCOUNTS = `
  query GetTalentSearchAccounts {
    talentSearchAccounts {
      items {
        advertiserId
        advertiserName
        hasConnectedTalentSearchAccess
        parentAdvertiserId
      }
    }
  }
`;

export const GET_USER_CONTEXT = `
  query GetTalentSearchUserContext($advertiserId: Int!, $hirerAccountId: String!) {
    userContext: talentSearchUserContextV2(
      input: { preferredAdvertiserId: $advertiserId }
    ) {
      user {
        userId
      }
      ats {
        id
        name
      }
    }
    redirectUrl(input: { hirerAccountId: $hirerAccountId, returnUri: "" })
  }
`;

const defaultRequestHeaders = {
  'x-seek-site': 'connected-talent-search',
  'Content-Type': 'application/json',
};

export async function fetchTalentSearchConnectAccounts(
  apiUrl: ApiUrl,
  token: string,
): Promise<TalentSearchAccount[]> {
  const response = await fetch(apiUrl, {
    method: 'post',
    headers: {
      ...defaultRequestHeaders,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ query: GET_TALENT_SEARCH_ACCOUNTS }),
  });

  const {
    data: {
      talentSearchAccounts: { items },
    },
  }: {
    data: {
      talentSearchAccounts: TalentSearchAccounts;
    };
  } = await response.json();

  const matchingAccounts = items.filter(
    ({ hasConnectedTalentSearchAccess }) => hasConnectedTalentSearchAccess,
  );

  if (!matchingAccounts.length) {
    return Promise.reject({ error: ErrorCode.NoConnectedTalentSearchAccess });
  }

  return matchingAccounts;
}

export async function fetchUserContext(
  apiUrl: ApiUrl,
  token: string,
  advertiserId: number,
): Promise<{
  userId: number;
  atsId: number;
  atsName: string;
  country: Country;
}> {
  const response = await fetch(apiUrl, {
    method: 'post',
    headers: {
      ...defaultRequestHeaders,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      query: GET_USER_CONTEXT,
      variables: {
        advertiserId,
        hirerAccountId: String(advertiserId),
      },
    }),
  });

  const {
    data: { userContext, redirectUrl },
  }: { data: TalentSearchUserContextResponse } = await response.json();

  const { user, ats } = userContext || {};

  if (!ats) {
    return Promise.reject({ error: ErrorCode.NoATSRelationship });
  }

  const url = new URL(redirectUrl);
  const siteName = getSiteNameFromHostname(url.hostname);
  const { country } = getClassificationFromSiteName(siteName);

  return {
    userId: user.userId,
    atsId: ats.id,
    atsName: ats.name,
    country: country ?? 'au',
  };
}
