import React, { useMemo } from 'react';

import { getSearchParams } from '../../utils/window';

import { useLocalStorage } from '../useLocalStorage';

import Context from './Context';
import { ContextType } from './types';
import { Country } from '@seek/melways-sites';

interface Props {
  children: React.ReactNode;
}
export const KEYS = {
  REGION: 'region',
} as const;

function PreferencesProvider({ children }: Props) {
  const [region, setRegion] = useLocalStorage<Country | null>(
    KEYS.REGION,
    null,
  );

  const context = useMemo<ContextType>(
    () => ({
      getRegion: () => {
        const newRegion = getSearchParams().region || region;
        return typeof newRegion === 'string'
          ? (newRegion.toLowerCase() as Country)
          : null;
      },
      setRegion: (newRegion: string) => {
        setRegion(newRegion.toLowerCase() as Country);
      },
    }),
    [region, setRegion],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

export default PreferencesProvider;
