import React from 'react';
import { Box, Dropdown } from 'braid-design-system';
import { useAuth } from '../../../shared/hooks/useAuth';

function AccountSelector() {
  const { tscAccounts, setAdvertiserId } = useAuth();
  const handleAccountSelection: React.FormEventHandler<HTMLSelectElement> = (
    e,
  ) => {
    e.preventDefault();
    setAdvertiserId(parseInt(e.currentTarget.value, 10));
  };

  return (
    <Box data-widget-id="accountSelector" display="flex">
      <Dropdown
        aria-label="Select an account"
        id="tsc-account-selector"
        placeholder="Select an account"
        value=""
        onChange={handleAccountSelection}
      >
        {tscAccounts.map((tscAccount) => (
          <option key={tscAccount.advertiserId} value={tscAccount.advertiserId}>
            {`${tscAccount.advertiserName} (${tscAccount.advertiserId})`}
          </option>
        ))}
      </Dropdown>
    </Box>
  );
}

export default AccountSelector;
