import {
  talentsearch_seek_com_au as productionConfig,
  talent_staging_seek_com_au as developmentConfig,
  ClientConfig,
} from '@seek/online-identity';

import { Environment, AuthConfig } from '../../../types';

// Note:
// 'stagingConfig' should come from @seek/online-identity however it does not exist in the package yet.
// Since @seek/online-identity is far behind version updates in this repo and we can not reliably
// test for auth changes, we are hard coding 'stagingConfig' here for now.
//
// TODO:
// Once TSC staging is ready, we can update @seek/online-identity to the latest version, add 'stagingConfig'
// for talentsearch staging to @seek/online-identity and then retrieve 'stagingConfig' from @seek/online-identity.
// eg.
//     import { talentsearch_staging_seek_com_au as stagingConfig } from '@seek/online-identity';
const stagingConfig = {
  audience: 'https://seek/api/talent',
  authDomain: 'authenticate.staging.seek.com',
  clientId: 'wIImFpU8q3pCTeCJdHCymfaS1kOCAK2T',
  environment: 'dev',
  realm: 'Username-Password-Authentication',
  redirectPath: '/callback',
  useRefreshTokens: true,
  contactUs: {
    en: 'https://talent.seek.com.au/contactus/',
  },
} as ClientConfig;

const configs = {
  [Environment.Development]: {
    ...developmentConfig,
    clientDomain: AuthConfig.ClientDomainDev,
    logoutUrl: AuthConfig.LogoutUrlDev,
    __disableHttps: true,
  },
  [Environment.Staging]: {
    ...stagingConfig,
    clientDomain: AuthConfig.ClientDomainStaging,
    logoutUrl: AuthConfig.LogoutUrlStaging,
  },
  [Environment.Production]: {
    ...productionConfig,
    clientDomain: AuthConfig.ClientDomain,
    logoutUrl: AuthConfig.LogoutUrl,
  },
};

function createClientConfig(environment: Environment): ClientConfig {
  const config = configs[environment];
  return config;
}

export default createClientConfig;
