import { Box, Inline, Strong, Text } from 'braid-design-system';
import React from 'react';
import { Details } from 'src/shared/hooks/useImpersonation/types';

import * as styles from './ImpersonationBanner.css';

interface Props {
  isAuthenticated: boolean;
  isImpersonating: boolean;
  impersonationDetails: Details | null;
}

const getActorDisplayText = (email: string): string => {
  let displayText = 'Operator';
  if (email && email.indexOf('@') > -1) {
    displayText = email.substring(0, email.indexOf('@'));
  }
  return displayText;
};

function ImpersonationBanner({
  isAuthenticated,
  isImpersonating,
  impersonationDetails,
}: Props) {
  if (!isAuthenticated && !isImpersonating) {
    return null;
  }

  return (
    impersonationDetails && (
      <Box className={styles.container} zIndex="sticky">
        <Box
          id="impersonation-banner"
          padding="small"
          background="brandAccent"
          textAlign="center"
        >
          <Inline alignY="center" space="small" align="center">
            <Text size="small">
              <Strong>
                {getActorDisplayText(impersonationDetails.actorEmail)}
              </Strong>{' '}
              is impersonating{' '}
              <Strong>{impersonationDetails.actAsEmail}</Strong>
            </Text>
          </Inline>
        </Box>
      </Box>
    )
  );
}

export default ImpersonationBanner;
