import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import Context from './Context';

import { EVENT_ACTIONS, EventActions, EventPayload } from './types';
import { useAppConfig } from '../useAppConfig';

interface Props {
  children: React.ReactNode;
}

function ParentWindowProvider({ children }: Props) {
  const hasMounted = useRef<boolean>(false);

  const { appVersion, branchName } = useAppConfig();
  const { pathname } = useLocation();

  const postMessage = useCallback(
    (action: EventActions, payload?: EventPayload) => {
      try {
        window.parent.postMessage(
          {
            pathname:
              branchName === 'master'
                ? pathname
                : pathname.replace('/', `/index-${appVersion}.html#`),
            action,
            ...(payload && { payload }),
          },
          '*',
        );
      } catch (ex) {
        //  Handle failed window event
      }
    },
    [appVersion, branchName, pathname],
  );

  const receivedParentWindowActions: any = useMemo(
    () => ({
      [EVENT_ACTIONS.REFRESH]: () => {
        window.location.reload();
      },
    }),
    [],
  );

  useEffect(() => {
    function handleMessage(event: MessageEvent): void {
      //  Filter all received messages to widgets
      //  other than the one that send the message
      const action = event.data?.action;

      //  This ensures that on a signIn / signOut all other
      //  widgets can react accordingly
      if (!action || event.data.pathname === pathname) {
        return;
      }

      if (action in receivedParentWindowActions) {
        receivedParentWindowActions[action]();
      }
    }

    if (!hasMounted.current) {
      hasMounted.current = true;
      window.addEventListener('message', handleMessage, false);

      //  Signals to the parent window that this
      //  iframe / widget can broadcast messages
      postMessage(EVENT_ACTIONS.REGISTER);
    }
  }, [receivedParentWindowActions, postMessage, pathname]);

  const value = useMemo(
    () => ({
      refresh: () => postMessage(EVENT_ACTIONS.REFRESH),
      sendEvent: (payload: EventPayload) =>
        postMessage(EVENT_ACTIONS.SEND_EVENT, payload),
    }),
    [postMessage],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export default ParentWindowProvider;
