import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { AuthN, ClientConfig } from '@seek/online-identity';
import { useLocation } from 'react-router-dom';

import { AUTH_IGNORED_PATHS } from '../../../sharedConfig';
import { TalentSearchAccount } from '../../../types';

import { useAppConfig } from '../useAppConfig';
import { usePreferences } from '../usePreferences';

import createClientConfig from './clientConfig';
import usePersistance from './usePersistence';
import {
  fetchTalentSearchConnectAccounts,
  fetchUserContext,
} from './authServices';

import { Identity } from './types';
import Context from './Context';

interface Props {
  children: React.ReactNode;
}

function AuthProvider({ children }: Props) {
  const { environment, apiUrl } = useAppConfig();
  const { setRegion, getRegion } = usePreferences();
  const { pathname, search } = useLocation();

  const clientConfigRef = useRef<Readonly<ClientConfig>>(
    createClientConfig(environment),
  );
  const {
    getAdvertiserId,
    getAtsId,
    getAtsName,
    getUserId,
    getAccessToken,
    getIdentity,
    setAccessToken,
    setAdvertiserId,
    setAtsId,
    setAtsName,
    setUserId,
  } = usePersistance(clientConfigRef.current.clientId);

  const authClientRef = useRef<AuthN | null>(null);
  //  Extracts any previously persisted values
  //  locally so that we don't have to re-fetch them
  const hasAuthenticatedRef = useRef(false);
  const accessTokenRef = useRef<string | null>(getAccessToken());
  const identityRef = useRef<Identity | null>(getIdentity());
  const advertiserId = getAdvertiserId();
  const atsId = getAtsId();
  const atsName = getAtsName();
  const userId = getUserId();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [tscAccounts, setTSCAccounts] = useState<TalentSearchAccount[]>([]);

  const getClient = () => {
    if (!authClientRef.current) {
      authClientRef.current = new AuthN(clientConfigRef.current);
    }

    return authClientRef.current as AuthN;
  };

  const handleRedirect = useCallback(
    async (redirectHandler: (origin: string, url: string) => Promise<void>) => {
      await getClient().handleRedirect(redirectHandler);
      setLoading(true);
    },
    [],
  );
  const clearAll = () => window?.localStorage?.clear();

  const handleSignOut = useCallback(async (returnTo?: string) => {
    clearAll();
    const logoutRedirect = returnTo
      ? `?returnTo=${encodeURIComponent(returnTo)}`
      : '';
    const { logoutUrl } = clientConfigRef.current;

    return getClient().logout(`${logoutUrl}${logoutRedirect}`);
  }, []);

  const handleGetIdentity = useCallback(async () => {
    if (identityRef.current) {
      return identityRef.current;
    }

    let identity = null;
    try {
      const client = getClient();
      identity = await client.getIdentity();
      identityRef.current = identity;
    } catch (ex: any) {
      if (ex?.error !== 'login_required') {
        throw ex;
      }
    }

    return identity;
  }, []);

  const handleAuthentication = useCallback(async () => {
    try {
      const client = getClient();

      if (!accessTokenRef.current) {
        accessTokenRef.current = (await client.getToken()) as string;
        setAccessToken(accessTokenRef.current);
      }

      if (!advertiserId) {
        const fetchedTSCAccounts = await fetchTalentSearchConnectAccounts(
          apiUrl,
          accessTokenRef.current,
        );

        if (fetchedTSCAccounts.length === 1) {
          const theOnlyTSCAccount = fetchedTSCAccounts[0];
          setAdvertiserId(theOnlyTSCAccount.advertiserId);

          const userContextResult = await fetchUserContext(
            apiUrl,
            accessTokenRef.current,
            theOnlyTSCAccount.advertiserId,
          );
          setAtsId(userContextResult.atsId);
          setAtsName(userContextResult.atsName);
          setUserId(userContextResult.userId);
          setRegion(userContextResult.country);
        } else if (fetchedTSCAccounts.length > 1) {
          setTSCAccounts(fetchedTSCAccounts);
        }
      }
    } catch (ex: any) {
      switch (ex.error) {
        case 'login_required': {
          break;
        }
        case 'invalid_grant': {
          return handleSignOut(pathname + search);
        }
        default: {
          setError(ex.error);
        }
      }
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiUrl, setRegion, pathname, search]);

  const handleSignIn = useCallback(async () => {
    await getClient().loginWithPopup();
    setLoading(true);
    return handleAuthentication();
  }, [handleAuthentication]);

  const renewToken = useCallback(async () => {
    const renewedAccessToken = (await getClient().getToken()) as string;
    accessTokenRef.current = renewedAccessToken;
    setAccessToken(renewedAccessToken);
    return renewedAccessToken;
  }, [setAccessToken]);

  const init = useCallback(async () => {
    authClientRef.current = new AuthN(clientConfigRef.current);
    const client = getClient();
    await client.init();
  }, []);

  useEffect(() => {
    (async () => {
      /*
       * Avoids automatically attempting to authenticate
       * When hitting the /oauth/integration or /callback path
       */
      if ((AUTH_IGNORED_PATHS as string[]).includes(pathname)) {
        return;
      }
      if (!hasAuthenticatedRef.current) {
        setLoading(true);
        await handleAuthentication();
        hasAuthenticatedRef.current = true;
      }
    })();
  }, [pathname, handleAuthentication]);

  useEffect(() => {
    (async () => {
      if (
        !advertiserId ||
        !accessTokenRef.current ||
        (atsId && atsName && userId && getRegion())
      ) {
        return;
      }

      const userContextResult = await fetchUserContext(
        apiUrl,
        accessTokenRef.current,
        advertiserId,
      );

      setAtsId(userContextResult.atsId);
      setAtsName(userContextResult.atsName);
      setUserId(userContextResult.userId);
      setRegion(userContextResult.country);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advertiserId]);

  const isAuthenticated = !loading && getAccessToken() !== null;
  const showAccountSelector =
    isAuthenticated && tscAccounts.length > 1 && !advertiserId;

  const value = useMemo(
    () => ({
      getToken: getAccessToken,
      renewToken,
      setAdvertiserId,
      getAdvertiserId,
      getAtsId,
      getAtsName,
      getUserId,
      handleRedirect,
      isAuthenticated,
      showAccountSelector,
      init,
      loading,
      error,
      tscAccounts,
      signIn: handleSignIn,
      signOut: handleSignOut,
      getIdentity: handleGetIdentity,
    }),
    [
      error,
      getAccessToken,
      getAdvertiserId,
      getAtsId,
      getAtsName,
      getUserId,
      handleGetIdentity,
      handleRedirect,
      handleSignIn,
      handleSignOut,
      init,
      isAuthenticated,
      loading,
      renewToken,
      setAdvertiserId,
      showAccountSelector,
      tscAccounts,
    ],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export default AuthProvider;
