import React, { useMemo } from 'react';
import { ApolloProvider, InMemoryCache } from '@apollo/client';

import { useAppConfig } from '../useAppConfig';
import { useAuth } from '../useAuth';

import createClient from './createClient';

interface Props {
  children: React.ReactNode;
}

const cache = new InMemoryCache();

function GraphQLProvider({ children }: Props) {
  const { apiUrl } = useAppConfig();
  const { getAdvertiserId, getToken, renewToken } = useAuth();
  const advertiserId = getAdvertiserId();

  const client = useMemo(
    () =>
      createClient(
        {
          apiUrl,
          fetch,
          getToken,
          renewToken,
          advertiserId,
        },
        cache,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [advertiserId],
  );

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default GraphQLProvider;
