import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { parseParams } from '../useSearchParams';
import { useParentWindow } from '../useParentWindow';
import { getZoneFromSite, siteConfig } from '@seek/melways-sites';
import Context from './Context';

import {
  MetricsEvent,
  SeekType,
  AccountType,
  WebComponentMetrics,
} from '../../../types/metrics';

import { useAuth } from '../useAuth';

interface Props {
  children: React.ReactNode;
}

function MetricsProvider({ children }: Props) {
  const { getAdvertiserId, getUserId } = useAuth();
  const { pathname, search } = useLocation();
  const parentWindow = useParentWindow();
  const value = useMemo(
    () => ({
      dispatchEvent: <P,>(event: MetricsEvent<P>, params: P) => {
        const { action, querySchema, getValue } = event;
        //  Parse all query string params
        //  and extract metrics
        const { metrics, ...restQueryParams } = parseParams(search, {
          ...querySchema,
          ...(search.includes('metrics') && {
            metrics: {
              type: (input: string): WebComponentMetrics =>
                JSON.parse(decodeURIComponent(input)),
            },
          }),
        });

        //  Construct common global parameters
        //  and parse query string values if required
        const userId = getUserId();
        const seekAdvertiserId = getAdvertiserId();

        const site = 'employer-seek-au';
        const tealiumProps = {
          siteLanguage: siteConfig[site].languages[0],
          siteCountry: siteConfig[site].classification.country,
          brand: siteConfig[site].classification.brand,
          zone: getZoneFromSite(site),
          isLoggedIn: false,
          siteSection: 'talent search',
        };
        const context = {
          pathname,
          ...(seekAdvertiserId && { seekAdvertiserId }),
          ...(userId && { userId }),
          isSearchCoupled: SeekType.Uncoupled,
          accountType: AccountType.Connected,
          ...restQueryParams,
          ...(metrics && {
            actionOrigin: metrics.actionOrigin,
            currentPage: metrics.actionOrigin,
          }),
          ...tealiumProps,
        };

        parentWindow.sendEvent({
          action,
          payload: getValue({ params, context }),
        });
      },
    }),
    [search, getUserId, getAdvertiserId, pathname, parentWindow],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export default MetricsProvider;
