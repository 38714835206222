import { setContext } from '@apollo/client/link/context';

import type { ClientOptions } from '../types';

function createAuthLink({ getToken, advertiserId }: ClientOptions) {
  return setContext(async (_, prevContext) => {
    const customHeaders = {
      'x-seek-site': 'connected-talent-search',
      ...(advertiserId && {
        advertiserId: `${advertiserId}`,
      }),
    };

    try {
      const token = getToken();

      return {
        ...prevContext,
        headers: {
          ...(token && { authorization: `Bearer ${token}` }),
          ...customHeaders,
        },
      };
    } catch (ex) {
      return {
        ...prevContext,
        headers: customHeaders,
      };
    }
  });
}

export default createAuthLink;
