//  Metrics that are passed in at
//  web component level
export interface WebComponentMetrics {
  actionOrigin: string;
  currentPage: string;
}

//  Metrics context that is passed into the getValue()
//  for each metrics action
export interface MetricsContext extends WebComponentMetrics {
  pathname: string;
  seekAdvertiserId: number;
  isSearchCoupled: SeekType;
  accountType: AccountType;
}

interface GetValueParams<P> {
  params: P;
  context: MetricsContext;
}

export interface MetricsLinkEvent<P> {
  action: UtagActions.Link;
  querySchema?: any;
  getValue: (getValueParams: GetValueParams<P>) => {
    eventName: string;
  } & P &
    MetricsContext;
}

export interface MetricsViewEvent<P> {
  action: UtagActions.View;
  querySchema?: any;
  getValue: (getValueParams: GetValueParams<P>) => {
    eventName: string;
  } & P &
    MetricsContext;
}

export type MetricsEvent<P> = MetricsLinkEvent<P> | MetricsViewEvent<P>;

export enum UtagActions {
  View = 'view',
  Link = 'link',
}

export enum LinkEvent {
  TsWidgetBtnLoginImpression = 'ts_widget_btn_login_displayed',
  TsWidgetBtnLoginInteracted = 'ts_widget_btn_login_pressed',
  TsWidgetBtnSeekProfileImpression = 'ts_widget_btn_seek_profile_displayed',
  TsWidgetBtnSeekProfileInteracted = 'ts_widget_btn_seek_profile_pressed',
  TsWidgetBtnSeekMatchingCandidateImpression = 'ts_widget_btn_seek_matching_candidate_displayed',
  TsWidgetBtnSeekJaMatchingCandidateImpression = 'ts-widget-btn-seek-ja-matching-candidate-impression',
  TsWidgetBtnMatchingCandidateSeekInteracted = 'ts_widget_btn_matching_candidate_seek_pressed',
  TsWidgetBtnMatchingCandidateJobadderSeekInteracted = 'ts-widget-btn-matching-candidate-jobadder-seek-interacted',
  TsWidgetBtnSeekSimilarCandidateImpression = 'ts_widget_btn_seek_similar_candidate_displayed',
  TsWidgetBtnSeekJaSimilarCandidateImpression = 'ts-widget-btn-seek-ja-similar-candidate-impression',
  TsWidgetBtnSeekSimilarCandidateProfileInteracted = 'ts_widget_btn_seek_similar_candidate_profile_pressed',
  TsWidgetBtnSeekJaSimilarCandidateSeekProfileInteracted = 'ts-widget-btn-seek-ja-similar-candidate-seek-profile-interacted',
  TsWidgetBtnTalentSearchImpression = 'ts_widget_btn_talent_search_displayed',
  TsWidgetBtnTalentSearchInteracted = 'ts_widget_btn_talent_search_pressed',
}

export enum ActionOrigin {
  JobCreationSuccess = 'job-creation-success',
}

export enum SeekType {
  Uncoupled = 'uncoupled',
}

export enum AccountType {
  Connected = 'connected',
}

export enum ProfileType {
  Seek = 'seek',
}
