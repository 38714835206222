import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { Box } from 'braid-design-system';

import ImpersonationBanner from '../ImpersonationBanner/ImpersonationBanner';

import { useAuth } from '../../hooks/useAuth';
import { useImpersonation } from 'src/shared/hooks/useImpersonation';
import AccountSelector from '../AccountSelector/AccountSelector';

interface Props extends RouteProps {
  views: {
    signedIn: React.ComponentType<any>;
    signedOut: React.ComponentType<any>;
  };
}

function AuthRoute({ views, ...restProps }: Props) {
  const { isAuthenticated, showAccountSelector, loading, error } = useAuth();
  const { isImpersonating, getDetails } = useImpersonation();

  if (loading) {
    return null;
  }

  if (error) {
    throw new Error(error);
  }

  const { signedIn: SignedIn, signedOut: SignedOut } = views;

  return (
    <Route {...restProps}>
      {isAuthenticated ? (
        <>
          {showAccountSelector ? (
            <Box padding="gutter" display="flex" justifyContent="center">
              <AccountSelector />
            </Box>
          ) : (
            <>
              <ImpersonationBanner
                isAuthenticated={isAuthenticated}
                isImpersonating={isImpersonating}
                impersonationDetails={getDetails()}
              />
              <SignedIn />
            </>
          )}
        </>
      ) : (
        <SignedOut />
      )}
    </Route>
  );
}

export default AuthRoute;
