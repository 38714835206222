import { createContext } from 'react';

import { ApiUrl, AppConfig, Environment } from '../../../types';

export default createContext<AppConfig>({
  appVersion: '',
  branchName: '',
  environment: Environment.Development,
  apiUrl: ApiUrl.Development,
  tealiumUrl: '',
  metricsForwarderUrl: '',
});
