import { ClientConfig } from '@seek/online-identity';

import { getSearchParams } from '../../utils/window';
import useLocalStorage from '../useLocalStorage/useLocalStorage';
import { Identity } from './types';

export const KEYS = {
  ADVERTISER_ID: 'cts_advertiser_id',
  ATS_ID: 'cts_ats_id',
  ATS_NAME: 'cts_ats_name',
  USER_ID: 'cts_user_id',
  ACCESS_TOKEN: 'hirer_sso_access_token',
  IDENTITY: 'hirer_sso_identity',
} as const;

function usePersistence(clientId: ClientConfig['clientId']) {
  const [advertiserId, setAdvertiserId] = useLocalStorage<number | null>(
    `${KEYS.ADVERTISER_ID}-${clientId}`,
    null,
  );
  const [atsId, setAtsId] = useLocalStorage<number | null>(
    `${KEYS.ATS_ID}-${clientId}`,
    null,
  );
  const [atsName, setAtsName] = useLocalStorage<string | null>(
    `${KEYS.ATS_NAME}-${clientId}`,
    null,
  );
  const [userId, setUserId] = useLocalStorage<number | null>(
    `${KEYS.USER_ID}-${clientId}`,
    null,
  );
  const [accessToken, setAccessToken] = useLocalStorage<string | null>(
    `${KEYS.ACCESS_TOKEN}-${clientId}`,
    null,
  );
  const [identity] = useLocalStorage<Identity | null>(
    `${KEYS.IDENTITY}-${clientId}`,
    null,
  );

  return {
    getAdvertiserId: () => {
      const _advertiserId = getSearchParams().advertiserId || advertiserId;
      return _advertiserId ? Number(_advertiserId) : null;
    },
    getAtsId: () => {
      const _atsId = getSearchParams().atsId || atsId;
      return _atsId ? Number(_atsId) : null;
    },
    getAtsName: () => {
      const _atsName = getSearchParams().atsName || atsName;
      return typeof _atsName === 'string' ? _atsName : null;
    },
    getUserId: () => {
      const _userId = userId;
      return _userId ? Number(_userId) : null;
    },
    getAccessToken: () => accessToken ?? null,
    getIdentity: () => identity,
    setAdvertiserId,
    setAtsId,
    setAtsName,
    setUserId,
    setAccessToken,
  };
}

export default usePersistence;
