import React, { Fragment } from 'react';

interface Props {
  components: Array<
    | [React.ComponentType<any>, Record<string, unknown>]
    | React.ComponentType<any>
  >;
  children: React.ReactNode;
}

function Providers({ components, children }: Props) {
  return (
    <Fragment>
      {components.reverse().reduce((acc, component) => {
        const [Provider, props] = Array.isArray(component)
          ? [component[0], component[1]]
          : [component, {}];
        return <Provider {...props}>{acc}</Provider>;
      }, children)}
    </Fragment>
  );
}

export default Providers;
