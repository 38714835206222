import React from 'react';
import { Alert, Text } from 'braid-design-system';

import { ErrorCode } from '../../../types';

interface Props {
  message: string | null;
}

const errorMap: any = {
  [ErrorCode.NoConnectedTalentSearchAccess]: 'No access to Talent Search',
  [ErrorCode.Unknown]: 'Talent Search is unavailable',
};

function ErrorMessage({ message }: Props) {
  if (message && message.includes('Invalid parameter')) {
    return (
      <Alert tone="caution">
        <Text>{message}</Text>
      </Alert>
    );
  }

  return (
    <Alert tone="critical">
      <Text>
        {(message && errorMap?.[message]) || errorMap[ErrorCode.Unknown]}
      </Text>
    </Alert>
  );
}

export default ErrorMessage;
