import { fromPromise } from '@apollo/client';
import { onError, ErrorResponse } from '@apollo/client/link/error';

import { GraphQLError } from 'graphql';

import { APIErrorCode } from '../../../../types';
import { ClientOptions } from '../types';

const hasAuthError = (errors?: ReadonlyArray<GraphQLError>) =>
  errors?.some(
    (error) => error?.extensions?.code === APIErrorCode.Unauthorised,
  );

const hasErrorCode = (
  code: number,
  networkError: ErrorResponse['networkError'],
) =>
  networkError &&
  'statusCode' in networkError &&
  networkError.statusCode === code;

function createErrorLink({ renewToken }: ClientOptions) {
  return onError((error) => {
    const { graphQLErrors, networkError, operation, forward } = error;
    if (hasAuthError(graphQLErrors) || hasErrorCode(401, networkError)) {
      return fromPromise(renewToken()).flatMap((renewedToken) => {
        const oldHeaders = operation.getContext().headers;
        operation.setContext({
          headers: {
            ...oldHeaders,
            authorization: `Bearer ${renewedToken}`,
          },
        });
        return forward(operation);
      });
    }
  });
}

export default createErrorLink;
