import React, { useRef, useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';

import { useAppConfig } from '../useAppConfig';

import Context from './Context';

import { DatadogRumConfig } from './types';
import { Environment } from '../../../types';

interface Props {
  children: React.ReactNode;
}

function DatadogRumProvider({ children }: Props) {
  const hasInjected = useRef<boolean>(false);
  const { appVersion, environment } = useAppConfig();
  const shouldInjectDatadogRum = environment === Environment.Production;

  const value: DatadogRumConfig = {
    applicationId: '7ba089c6-a89a-48c6-ba7b-6a4f262086a7',
    clientToken: 'pubbfb54f97aa4ecadb19891ebe0724ea06',
    site: 'datadoghq.com',
    service: 'talent-search-ui-connected-widgets',
    env: environment,
    version: appVersion,
    sampleRate: 100,
    replaySampleRate: 0, // <-- percentage of sessions that will have replaying the session, set to zero to not record anything.
    useCrossSiteSessionCookie: true, // <-- allows the RUM SDK to run when the site is loaded from another one (iframe).
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  };

  useEffect(() => {
    if (shouldInjectDatadogRum && !hasInjected.current) {
      datadogRum.init(value);

      // Uncomment the following line if you want the user sessions to be recorded for later playback.
      // datadogRum.startSessionReplayRecording();
    }
    hasInjected.current = true;
  });

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export default DatadogRumProvider;
