import React, { Component } from 'react';

import ErrorMessage from './ErrorMessage';

import APIError from '../../../shared/utils/APIError';

interface Props {
  onUnauthorised: () => void;
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  message: string | null;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
      message: null,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error | APIError): void {
    if (error instanceof APIError && error.statusCode === 401) {
      return this.props.onUnauthorised();
    }
    this.setState({ message: error.message });
  }

  render() {
    const { children } = this.props;
    const { hasError, message } = this.state;

    if (hasError) {
      return <ErrorMessage message={message} />;
    }
    return children;
  }
}

export default ErrorBoundary;
