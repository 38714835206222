import { createContext } from 'react';

import { EventPayload } from './types';

export default createContext<{
  refresh: () => void;
  sendEvent: (payload: EventPayload) => void;
}>({
  refresh: () => {},
  sendEvent: () => {},
});
