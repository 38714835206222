import React from 'react';
import { useLocation } from 'react-router-dom';

import ErrorBoundary from './ErrorBoundary';

import { useAuth } from '../../../shared/hooks/useAuth';

interface Props {
  children: React.ReactNode;
}

function ErrorBoundaryHandler({ children }: Props) {
  const { signOut } = useAuth();
  const { pathname, search } = useLocation();

  const handleUnauthorised = () => {
    const returnTo = `${pathname}${search}`;
    signOut(returnTo);
  };

  return (
    <ErrorBoundary onUnauthorised={handleUnauthorised}>
      {children}
    </ErrorBoundary>
  );
}

export default ErrorBoundaryHandler;
