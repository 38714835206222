import { useEffect } from 'react';

import { useAuth } from '../../shared/hooks/useAuth';

function Integration() {
  const { init } = useAuth();

  useEffect(() => {
    (async () => {
      await init();
    })();
  }, [init]);

  return null;
}

export default Integration;
