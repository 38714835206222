import qs from 'query-string';

import { DefaultQuerySchema } from '../../../types';

function parseParams<T extends DefaultQuerySchema>(search: string, schema: T) {
  const searchParams = qs.parse(search);
  const parsedParams: any = {};
  const invalidParams: string[] = [];

  for (const key of Object.keys(schema)) {
    const {
      type,
      defaultValue,
      required = false,
      pattern,
      helpText,
      oneOf,
    } = schema[key];

    const value =
      key in searchParams ? type(searchParams[key] as string) : defaultValue;

    const hasOneOfAndIsInvalid = oneOf && !oneOf.includes(value);
    const hasPatternAndIsInvalid = pattern && !pattern.test(value);
    const isRequiredAndHasNoValue = required && !Boolean(value);
    const isValid =
      !hasPatternAndIsInvalid &&
      !isRequiredAndHasNoValue &&
      !hasOneOfAndIsInvalid;

    if (hasOneOfAndIsInvalid) {
      invalidParams.push(
        `Invalid parameter ${key}: ${
          helpText?.oneOf ||
          `should be ${oneOf?.map((x) => `"${x}"`).join(' or ')}`
        }`,
      );
    }

    if (hasPatternAndIsInvalid) {
      invalidParams.push(
        `Invalid parameter ${key}: ${helpText?.pattern || 'invalid pattern'}`,
      );
    }

    if (isRequiredAndHasNoValue) {
      invalidParams.push(
        `Invalid parameter ${key}: ${helpText?.required || 'required'}`,
      );
    }

    if (isValid) {
      parsedParams[key] = value;
    }
  }

  if (invalidParams.length) {
    const [firstInvalidParam] = invalidParams;
    throw new Error(firstInvalidParam);
  }

  return parsedParams;
}

export default parseParams;
