import { HttpLink } from '@apollo/client';

import type { ClientOptions } from '../types';

function createHttpLink({ apiUrl, fetch }: ClientOptions) {
  return new HttpLink({
    uri: apiUrl,
    fetchOptions: { fetch },
  });
}

export default createHttpLink;
