import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../shared/hooks/useAuth';

function Callback() {
  const { replace } = useHistory();
  const { handleRedirect } = useAuth();

  useEffect(() => {
    (async () => {
      await handleRedirect(async (_, url: string) => replace(url));
    })();
  }, [handleRedirect, replace]);

  return null;
}

export default Callback;
