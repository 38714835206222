import { ApolloClient, InMemoryCache, from } from '@apollo/client';

import createHttpLink from './middleware/httpLink';
import createAuthLink from './middleware/authLink';
import createErrorLink from './middleware/errorLink';

import { ClientOptions } from './types';

function createClient(clientOptions: ClientOptions, cache: InMemoryCache) {
  return new ApolloClient({
    link: from([
      createAuthLink(clientOptions),
      createErrorLink(clientOptions),
      createHttpLink(clientOptions),
    ]),
    cache,
  });
}

export default createClient;
