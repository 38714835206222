import React from 'react';
import { useParams } from 'react-router-dom';
import loadable from 'sku/@loadable/component';

const BUTTONS = {
  VIEW_SEEK_PROFILE: 'viewSeekProfile',
  SIGN_IN_OUT: 'signInOut',
  TALENT_SEARCH: 'talentSearch',
} as const;

type Buttons = (typeof BUTTONS)[keyof typeof BUTTONS];

const variations = {
  [BUTTONS.VIEW_SEEK_PROFILE]: loadable(
    () => import('./components/ViewSeekProfile/ViewSeekProfile'),
  ),
  [BUTTONS.SIGN_IN_OUT]: loadable(
    () => import('./components/SignInOut/SignInOut'),
  ),
  [BUTTONS.TALENT_SEARCH]: loadable(
    () => import('./components/TalentSearch/TalentSearch'),
  ),
};

function Scene() {
  const { variation }: { variation: Buttons } = useParams();
  const ButtonComponent = variations[variation];

  return ButtonComponent ? <ButtonComponent /> : null;
}

export default Scene;
