import { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import parseParams from './parseParams';

import { DefaultQuerySchema, QuerySchema } from '../../../types';

export const sideEffects = {
  useLocation,
};

function useSearchParams<T extends DefaultQuerySchema>(schema: T) {
  const { search } = sideEffects.useLocation();
  const [state, setState] = useState<QuerySchema<T>>(
    parseParams<T>(search, schema),
  );
  const prevSearch = useRef<string>(search);

  useEffect(() => {
    if (prevSearch.current !== search) {
      prevSearch.current = search;
      setState(parseParams(search, schema));
    }
  }, [schema, search]);

  return state;
}

export default useSearchParams;
