import React from 'react';
import loadable from 'sku/@loadable/component';
import { useRouteMatch, useParams } from 'react-router-dom';

import AuthRoute from '../../shared/components/AuthRoute/AuthRoute';

const VARIATIONS = {
  SEEK_AND_COMBINED: 'seekAndCombined',
  SEEK_OR_COMBINED: 'seekOrCombined',
} as const;

type Variations = (typeof VARIATIONS)[keyof typeof VARIATIONS];

const variations = {
  [VARIATIONS.SEEK_AND_COMBINED]: {
    signedIn: loadable(() => import('./components/SeekAndCombined')),
    signedOut: loadable(() => import('./components/SignIn')),
  },
  [VARIATIONS.SEEK_OR_COMBINED]: {
    signedIn: loadable(() => import('./components/SeekOrCombined')),
    signedOut: loadable(() => import('./components/SignIn')),
  },
};

function Scene() {
  const { path } = useRouteMatch();
  const { variation }: { variation: Variations } = useParams();

  const views =
    variations?.[variation] || variations[VARIATIONS.SEEK_OR_COMBINED];

  return <AuthRoute exact path={path} views={views} />;
}

export default Scene;
