import React from 'react';

import Context from './Context';

import { AppConfig } from '../../../types';

interface Props {
  children: React.ReactNode;
  config: AppConfig;
}

function AppConfigProvider({ config, children }: Props) {
  return <Context.Provider value={config}>{children}</Context.Provider>;
}

export default AppConfigProvider;
