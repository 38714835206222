import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App/App';

import { AppConfigProvider } from './shared/hooks/useAppConfig';

import { ClientContext } from './types';

export default ({ appConfig }: ClientContext) => {
  hydrate(
    <BrowserRouter>
      <AppConfigProvider config={appConfig}>
        <App />
      </AppConfigProvider>
    </BrowserRouter>,
    document.getElementById('app'),
  );
};
