import { useContext } from 'react';

import Context from './Context';

import { DatadogRumConfig } from './types';

function useDatadogRum() {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      'useDatadogRum must be called from within DatadogRumProvider',
    );
  }

  return context as DatadogRumConfig;
}

export default useDatadogRum;
